import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import "katex/dist/katex.css"
import "./publications.scss"

const PublicationTemplate = ({ data }) => {
  const { frontmatter: metadata, html } = data.markdownRemark
  return (
    <Layout>
      <SEO title={metadata.title} />
      <base href="/"></base>
      <span className="conference">{metadata.publication}</span>
      <h1>{metadata.title}</h1>
      <span>{metadata.authors.join(", ")}</span>

      {metadata.teaser && (
        <figure>
          <img src={metadata.teaser.image} alt={metadata.title}></img>
          <p dangerouslySetInnerHTML={{ __html: metadata.teaser.desc }}></p>
        </figure>
      )}

      <main dangerouslySetInnerHTML={{ __html: html }}></main>
    </Layout>
  )
}

PublicationTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object.isRequired,
      html: PropTypes.string.isRequired,
    }),
  }),
}

export default PublicationTemplate

export const query = graphql`
  query PublicationQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        year
        authors
        publication
      }
    }
  }
`
